/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './scss/trivia-modal.scss';
@import './scss/zoom-animation.scss';
@import './scss/bleacr-card.scss';
@import './scss//miami-verify.scss';

html {
    height: 100%;
    font-family: 'Roboto', sans-serif;
}

.chat-body {
    background-color: transparent;
}

.webclient-body {
    background-color: #f5f5f6;
    margin: 0;
    a {
        text-decoration: none;
        color: #000000;
    }
}

#timeline {
    mat-card {
        .card-headline {
            position: absolute;
            display: flex;
            left: 0px;
            top: 0px;
            width: 95%;
        }
        .card-foot {
            position: absolute;
            display: flex;
            left: 0px;
            bottom: 1px;
            width: 100%;
            background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);

            .text-container {
                width: 100%;
                p {
                    margin: 1% 0%;
                }
            }
        }
        .mat-card-image:first-child {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }
        .title {
            width: 90%;
            h2 {
                margin: 3% 0%;
            }
        }
    }

    .subtitle {
        margin: 0rem 0.5rem 1rem;
    }
    .timeline-item-header h5,
    .timeline-item-header h2 {
        margin-bottom: 0.5rem;
    }
    .timeline-item-header {
        margin: 0 0.5rem;
    }
}

.drawer {
    width: 100vw;
    height: 100vh;
    background-color: #fafafa;
    position: fixed;
    top: 0;
    right: -100vw;
    z-index: 2;
    transition: all 0.3s ease-out;
    overflow-y: scroll;
    overflow-x: hidden;

    &.active {
        right: 0;
    }
}

.noBlue {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mat-bottom-sheet-container {
    background-color: #f3f3f3;
}

@keyframes rotate {
    100% {
        transform: rotateZ(360deg);
    }
}
#minutes-container,
#hours-container,
#seconds-container {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
#hours-container {
    animation: rotate 43200s infinite linear;
    #hours {
        height: 36%;
        left: 48.25%;
        position: absolute;
        top: 23%;
        transform-origin: 50% 75%;
        width: 3.5%;
        z-index: 0;
    }
}
#minutes-container {
    transition: transform 0.3s cubic-bezier(0.4, 2.08, 0.55, 0.44);
    #minutes {
        height: 45%;
        left: 48.25%;
        position: absolute;
        top: 14%;
        transform-origin: 50% 80%;
        width: 3.5%;
        z-index: 0;
    }
}
#seconds-container {
    transition: transform 0.2s cubic-bezier(0.4, 2.08, 0.55, 0.44);
    #seconds {
        height: 45%;
        left: 49.25%;
        position: absolute;
        top: 14%;
        transform-origin: 50% 80%;
        width: 1.5%;
        z-index: 4;
        transition: rotateZ 0.5s ease-in-out;
    }
}

.checkbox-wrap-fix {
    .mat-checkbox-layout {
        white-space: normal;

        .mat-checkbox-inner-container {
            margin-top: 4px;
        }
    }
}

@media (prefers-color-scheme: dark) {
    .webclient-body {
        background: #000000;
    }
    .color-scheme-background {
        background: #000000;
    }
}

@media (prefers-color-scheme: light) {
    .color-scheme-background {
        background: #f2f2f7;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-checkbox-layout {
    white-space: normal !important;
}
.mat-checkbox-background,
.mat-checkbox-frame {
    border-radius: 50% !important;
}

mat-checkbox.ng-dirty.ng-touched.ng-invalid {
    color: rgba(255, 64, 64, 1);
    .mat-checkbox-background {
        background-color: rgba(194, 25, 25, 0.6);
    }

    &.mat-checkbox-focused {
        .mat-ink-ripple {
            background-color: rgba(194, 25, 25, 0.6);
        }
    }
}
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background: rgba(43, 49, 113, 0.85) !important;
}

.mat-checkbox-checkmark-path {
    stroke: #e0e622 !important;
}

mat-dialog-container {
    background: unset !important;
}

.delete-dialog,
.block-user-dialog {
    background: #646464 !important;
    border-radius: 6px;
}

.mat-menu-content {
    padding: 0 !important;
}

.alt-menu {
    display: none;
}
//
.oop-dialog {
    .prompt-question {
        color: #fafafa !important;
    }
}

@media (prefers-color-scheme: dark) {
    .oop-dialog {
        background-color: #1e1e29;
    }
}
