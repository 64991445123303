$miami-open: #E76A21;

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.between {
    align-items: center;
    justify-content: space-between;
}

.miami-btn {
    box-sizing: border-box;
    background-color: $miami-open;
    border: none;
    color: white;
    padding: 0.6rem 6%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    border-radius: 21px;
}

label.miami-btn {
    input[type='file'] {
        display: none;
    }

    &.disabled {
        cursor: default;
    }
}

.disabled {
    background-color: #e76a2185;
}
