/*---------- ZOOM IN ----------*/

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}

.animated {
    -webkit-animation-duration: 250ms;
    animation-duration: 250ms;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}
