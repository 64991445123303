app-complete-modal {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 250ms;

    &.active {
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.32);
    }
}
